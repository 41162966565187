// Generated by Framer (4c611f3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["r0RPMTFSf"];

const variantClassNames = {r0RPMTFSf: "framer-v-i1x3f"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, C8sjnuAuF: image ?? props.C8sjnuAuF ?? {src: new URL("assets/MuSf8aj7WGi4nEZ979XySM3HvNo.svg", import.meta.url).href}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, C8sjnuAuF, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "r0RPMTFSf", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-BjW22", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fit", intrinsicHeight: 495, intrinsicWidth: 752, sizes: "min(752px, 100vw)", ...toResponsiveImage(C8sjnuAuF)}} className={cx("framer-i1x3f", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"r0RPMTFSf"} ref={ref} style={{...style}}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-BjW22 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BjW22 .framer-1j3xka7 { display: block; }", ".framer-BjW22 .framer-i1x3f { height: 495px; position: relative; width: 752px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 495
 * @framerIntrinsicWidth 752
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"C8sjnuAuF":"image"}
 */
const FramerQmfR4Lz3R: React.ComponentType<Props> = withCSS(Component, css, "framer-BjW22") as typeof Component;
export default FramerQmfR4Lz3R;

FramerQmfR4Lz3R.displayName = "Elements / Image Map";

FramerQmfR4Lz3R.defaultProps = {height: 495, width: 752};

addPropertyControls(FramerQmfR4Lz3R, {C8sjnuAuF: {__defaultAssetReference: "data:framer/asset-reference,MuSf8aj7WGi4nEZ979XySM3HvNo.svg?originalFilename=World.svg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerQmfR4Lz3R, [])